import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  brandColours,
  brandFonts,
  fontSize,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { slugify } from '../utils';

const StyledProductFilters = styled.aside`
  padding: 20px;
  margin-bottom: 50px;
  background-color: ${standardColours.lighterGrey};
  font-family: ${brandFonts.secondary};

  ${minBreakpointQuery.large`
    display: flex;
    justify-content: space-between;
    gap: 25px;
    margin-bottom: 70px;
  `}
`;

const StyledForm = styled.form``;

const StyledGroups = styled.div`
  display: grid;
  gap: 25px;

  ${minBreakpointQuery.large`
    display: flex;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 40px;
  `}
`;

const StyledCheckboxGroup = styled.fieldset`
  padding: 0;
  border: none;
`;

const StyledCheckboxHeading = styled.legend`
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-top: 1px solid ${standardColours.lightGrey};
  border-bottom: 1px solid ${standardColours.lightGrey};
  cursor: pointer;

  ${maxBreakpointQuery.large`
    margin-bottom: 5px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 30px;
  `}

  &:after {
    content: '';
    position: absolute;
    right: 0;
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 3px solid ${brandColours.primary};
    border-top: none;
    border-left: none;
    padding-inline: 0;

    ${({ display }) => {
      if (display) {
        return css`
          top: 60%;
          transform: translateY(calc(-50% - 2px)) rotate(-135deg);
        `;
      } else {
        return css`
          top: 50%;
          transform: translateY(calc(-50% - 2px)) rotate(45deg);
        `;
      }
    }}
  }
`;

const StyledOptions = styled.div`
  display: ${({ display }) => (display ? 'block' : 'none')};
`;

const StyledOption = styled.div`
  margin-top: 16px;
`;

// moved up for checked styling
const StyledText = styled.label`
  position: relative;
  display: block;
  padding-left: 30px;
  color: ${standardColours.black};
  cursor: pointer;
  ${fontSize(14)}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid ${standardColours.lightGrey};
    transition: ${standardTransition('background-color')};
  }

  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 7px;
    height: 10px;
    width: 5px;
    border: 2px solid ${standardColours.transparent};
    border-top: none;
    border-left: none;
    border-radius: 1px;
    transform: rotate(45deg);
    transition: ${standardTransition('border-color')};
  }

  &:hover {
    &:after {
      border-color: ${brandColours.tertiary};
    }
  }
`;

const StyledCheckbox = styled.input`
  ${visuallyHidden()};

  &:checked + ${StyledText} {
    &:before {
      background-color: ${brandColours.tertiary};
    }

    &:after {
      border-color: ${standardColours.white};
    }
  }
`;

const StyledBottom = styled.div`
  ${minBreakpointQuery.large`
    display: flex;
    align-items: start;
    gap: 25px;
  `}
`;

const StyledClearFilters = styled.button`
  margin-top: 20px;
  background: none;
  border: none;
  padding: 0;
  font-family: ${brandFonts.secondary};
  color: ${brandColours.primary};

  ${minBreakpointQuery.large`
    margin-top: 16px;
  `}
`;

const StyledSortWrapper = styled.div`
  ${maxBreakpointQuery.large`
    margin-top: 20px;
  `}
`;

const StyledSort = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
`;

const StyledSortText = styled.span``;

const StyledSortSelectWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  border-top: 1px solid ${standardColours.lightGrey};
  border-bottom: 1px solid ${standardColours.lightGrey};

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 2px;
    display: block;
    width: 12px;
    height: 12px;
    border: 3px solid ${brandColours.primary};
    border-top: none;
    border-left: none;
    border-radius: 1px;
    pointer-events: none;
    transform: translateY(calc(-50% - 2px)) rotate(45deg);
  }
`;

const StyledSortSelect = styled.select`
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  font-family: ${brandFonts.secondary};
  ${fontSize(16)};
  background: none;
  border: none;
  appearance: none;

  ${minBreakpointQuery.large`
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 30px;
  `}
`;

const ProductFilters = ({ initialProducts, products, setProducts }) => {
  const [filterDisplay, setFilterDisplay] = useState([
    {
      name: 'Size',
      display: false,
    },
    {
      name: 'Light',
      display: false,
    },
    {
      name: 'Care Level',
      display: false,
    },
    {
      name: 'Pet Friendly',
      display: false,
    },
  ]);
  const [activeFilters, setActiveFilters] = useState(false);
  const [sortBy, setSortBy] = useState('stock');

  const sizeFilters = [];
  const lightFilters = [];
  const careLevelFilters = [];
  const petFriendlyFilters = [];

  const addMetafieldFilter = (arrayName, value) => {
    JSON.parse(value).forEach(value => {
      return arrayName.push(value);
    });
  };

  initialProducts.forEach(({ metafields }) => {
    metafields.map(({ key, value }) => {
      if (key === 'size') {
        return addMetafieldFilter(sizeFilters, value);
      } else if (key === 'light') {
        return addMetafieldFilter(lightFilters, value);
      } else if (key === 'care_level') {
        return addMetafieldFilter(careLevelFilters, value);
      } else if (key === 'pet_friendly') {
        return addMetafieldFilter(petFriendlyFilters, value);
      } else {
        return false;
      }
    });
  });

  const filters = [];

  const sizeOrder = ['Small', 'Medium', 'Large'];
  const lightOrder = [
    'Shade Tolerant',
    'Medium / Bright Light',
    'Bright / Direct Light',
  ];
  const careLevelOrder = [
    'Ideal for the new plant parent',
    'Plant savvy',
    'Plant guru (needs optimal conditions)',
  ];

  if (sizeFilters.length > 0) {
    filters.push({
      name: 'Size',
      items: [
        ...new Set(
          sizeFilters.sort(function (a, b) {
            return sizeOrder.indexOf(a) - sizeOrder.indexOf(b);
          })
        ),
      ],
    });
  }

  if (lightFilters.length > 0) {
    filters.push({
      name: 'Light',
      items: [
        ...new Set(
          lightFilters.sort(function (a, b) {
            return lightOrder.indexOf(a) - lightOrder.indexOf(b);
          })
        ),
      ],
    });
  }

  if (careLevelFilters.length > 0) {
    filters.push({
      name: 'Care Level',
      items: [
        ...new Set(
          careLevelFilters.sort(function (a, b) {
            return careLevelOrder.indexOf(a) - careLevelOrder.indexOf(b);
          })
        ),
      ],
    });
  }

  if (petFriendlyFilters.length > 0) {
    filters.push({
      name: 'Pet Friendly',
      items: [...new Set(petFriendlyFilters)],
    });
  }

  const sort = (sortableProducts, sortOption, sortDirection) => {
    if (sortOption === 'price') {
      const sortedProducts = [...sortableProducts].sort(
        (a, b) =>
          a.priceRange.minVariantPrice.amount -
          b.priceRange.minVariantPrice.amount
      );
      return sortDirection === 'ASC'
        ? sortedProducts
        : sortedProducts.reverse();
    } else {
      return [...sortableProducts].sort(
        (a, b) =>
          initialProducts.findIndex(product => product.id === a.id) -
          initialProducts.findIndex(product => product.id === b.id)
      );
    }
  };

  const clearFilters = () => {
    document.getElementById('filters').reset();
    setActiveFilters(false);
    setProducts(initialProducts);
  };

  const changeSortBy = e => {
    if (e.target.value === 'high-low') {
      setProducts(sort(products, 'price'));
      setSortBy('price-desc');
    } else if (e.target.value === 'low-high') {
      setProducts(sort(products, 'price', 'ASC'));
      setSortBy('price-asc');
    } else if (e.target.value === 'stock') {
      setProducts(sort(products));
      setSortBy('stock');
    }
  };

  const handleChange = e => {
    const form = e.currentTarget;
    const formData = new FormData(form);

    const selectedSizes = formData.getAll('size');
    const selectedLight = formData.getAll('light');
    const selectedCareLevel = formData.getAll('care-level');
    const selectedPetFriendly = formData.getAll('pet-friendly');

    if (
      selectedSizes.length > 0 ||
      selectedLight.length > 0 ||
      selectedCareLevel.length > 0 ||
      selectedPetFriendly.length > 0
    ) {
      setActiveFilters(true);
    }

    const filteredProducts = initialProducts.filter(({ metafields }) => {
      const productSizes = metafields.find(({ key }) => key === 'size');
      const productLight = metafields.find(({ key }) => key === 'light');
      const productCareLevel = metafields.find(
        ({ key }) => key === 'care_level'
      );
      const productPetFriendly = metafields.find(
        ({ key }) => key === 'pet_friendly'
      );

      return (
        (selectedSizes.length > 0
          ? productSizes
            ? selectedSizes.some(item =>
                JSON.parse(productSizes.value).includes(item)
              )
            : false
          : true) &&
        (selectedLight.length > 0
          ? productLight
            ? selectedLight.some(item =>
                JSON.parse(productLight.value).includes(item)
              )
            : false
          : true) &&
        (selectedCareLevel.length > 0
          ? productCareLevel
            ? selectedCareLevel.some(item =>
                JSON.parse(productCareLevel.value).includes(item)
              )
            : false
          : true) &&
        (selectedPetFriendly.length > 0
          ? productPetFriendly
            ? selectedPetFriendly.some(item =>
                JSON.parse(productPetFriendly.value).includes(item)
              )
            : false
          : true)
      );
    });

    setProducts(
      filteredProducts.length > 0
        ? sort(
            filteredProducts,
            sortBy.includes('price') ? 'price' : undefined,
            sortBy.includes('asc') ? 'ASC' : undefined
          )
        : []
    );
  };

  return (
    <StyledProductFilters>
      <StyledForm id="filters" onChange={e => handleChange(e)}>
        <StyledGroups>
          {filters.map(({ name, items }, id) => {
            const display = filterDisplay.find(filter => filter.name === name)
              .display;

            return (
              <StyledCheckboxGroup key={id}>
                <StyledCheckboxHeading
                  display={display}
                  onClick={() => {
                    setFilterDisplay([
                      ...filterDisplay,
                      (filterDisplay[id].display = !display),
                    ]);
                  }}
                >
                  {name}
                </StyledCheckboxHeading>
                <StyledOptions display={display}>
                  {items.map((item, id) => {
                    const identifier = slugify(item);

                    return (
                      <StyledOption key={id}>
                        <StyledCheckbox
                          type="checkbox"
                          id={identifier}
                          name={slugify(name)}
                          value={item}
                        />
                        <StyledText for={identifier}>{item}</StyledText>
                      </StyledOption>
                    );
                  })}
                </StyledOptions>
              </StyledCheckboxGroup>
            );
          })}
        </StyledGroups>
      </StyledForm>
      <StyledBottom>
        {activeFilters && products.length !== initialProducts.length && (
          <StyledClearFilters onClick={() => clearFilters()}>
            Clear all filters
          </StyledClearFilters>
        )}
        <StyledSortWrapper>
          <StyledSort for="sort">
            <StyledSortText>Sort:</StyledSortText>
            <StyledSortSelectWrapper>
              <StyledSortSelect name="sort" id="sort" onChange={changeSortBy}>
                <option value="stock">In stock</option>
                <option value="high-low">Price High to Low</option>
                <option value="low-high">Price Low to High</option>
              </StyledSortSelect>
            </StyledSortSelectWrapper>
          </StyledSort>
        </StyledSortWrapper>
      </StyledBottom>
    </StyledProductFilters>
  );
};

export default ProductFilters;
