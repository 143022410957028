import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { sectionMargins } from '../styles';
import { Container } from './ui';
import ListSlider from '../components/ListSlider';
import ProductFilters from './ProductFilters';
import ProductList from './ProductList';
import ContactForm from './ContactForm';

const StyledCategoryProductList = styled.section`
  ${sectionMargins()};
`;

const CategoryProductList = ({ items: initialItems }) => {
  const {
    allShopifyCollection: { nodes },
  } = useStaticQuery(graphql`
    query CategoryProductListQuery {
      allShopifyCollection(sort: { fields: updatedAt, order: DESC }) {
        nodes {
          text: title
          slug: handle
        }
      }
    }
  `);

  const [items, setItems] = useState(initialItems);

  return (
    <StyledCategoryProductList>
      <Container>
        <ListSlider
          parentSlug="shop"
          firstSlideText="All Products"
          items={nodes}
        />
        <ProductFilters
          initialProducts={initialItems}
          products={items}
          setProducts={setItems}
        />
        {items.length > 0 ? (
          <ProductList items={items} />
        ) : (
          <ContactForm
            text="We're sorry. We don't have what you're looking for in our greenhouse right now. New plants arrive weekly so check back again soon or drop us a message below."
            name="Shop Form"
          />
        )}
      </Container>
    </StyledCategoryProductList>
  );
};

export default CategoryProductList;
